<template>
  <div class="">
    <header class="header">
      <i class="title-icon iconfont icon-yishiguanli"></i>
      <h5 style="font-size: 16px">医生药师补贴</h5>
    </header>
    <div class="gg-container">
      <div class="search-container" style="justify-content: space-between">
        <div
          style="margin-left: auto; display: flex; justify-content: space-around"
        >
          <div
            class="search-container-fn-input"
            v-if="operateList.indexOf('send_perk') > -1"
          >
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-refresh"
              style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
              @click="sendIncome(multipleSelectionPres[0])"
              v-if="multipleSelectionPres.length == 1"
              >发放补贴
            </el-button>
          </div>
          <div
            class="search-container-fn-input"
            v-if="operateList.indexOf('update_table') > -1"
          >
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit-outline"
              style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
              @click="handleEditTableHead"
              >编辑表头
            </el-button>
          </div>
          <div
            class="search-container-fn-input"
            v-if="operateList.indexOf('reset_query') > -1"
          >
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-refresh"
              style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
              @click="handleResetSearch"
              >重置查找
            </el-button>
          </div>
          <!--对应第三方图标的button样式-->
        </div>
      </div>
      <el-table
        border
        size="mini"
        v-loading="listLoading"
        :header-cell-style="{ 'text-align': 'center' }"
        :data="tableData"
        height="725"
        @selection-change="handleSelectionChange"
        style="width: 100%;z-index:0"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column type="index" align="center" width="50">
        </el-table-column>
        <template v-for="(column, index) in tableHead">
          <el-table-column
            :prop="column.column_prop"
            :label="
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-if="column.field_type === 'textBtn' && column.visible === true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <a
                style="font-size: 12px; color: #2379fb"
                @click.prevent="editArticle('view', scope.row)"
              >
                {{ scope.row[column.column_prop] }}
              </a>
            </template>
          </el-table-column>
          <el-table-column
            :prop="column.column_prop"
            :label="
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-else-if="
              column.field_type === 'select' && column.visible === true
            "
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-switch
                class="switch"
                @change="changeExamine(scope.row)"
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                active-text="启用"
                inactive-text="禁用"
                active-color="#13ce66"
                inactive-color="#C8C8C8"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            :sortable="tableHeadSortable.includes(column.column_prop)"
            :prop="column.column_prop"
            :label="
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-else-if="column.visible === true"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <a v-if="column.column_prop == 'supervise_status'">
                {{ scope.row.supervise_status | formatSuperviseStatus }}
              </a>
              <a v-else>{{ scope.row[column.column_prop] }}</a>
            </template>
            <template #header>
              <el-popover
                placement="bottom"
                title=""
                min-width="160"
                trigger="click"
                v-if="
                  searchTableHead.filter(
                    (item) => item.name == column.column_prop
                  ).length > 0
                "
              >
                <span slot="reference" class="search-header">
                  <span class="search-title">{{
                    column.column_label_user_definition
                      ? column.column_label_user_definition
                      : column.column_label
                  }}</span>
                  <i
                    style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                    class="el-icon-search"
                    :class="{
                      isSearch: searchTableHead.filter(
                        (item) => item.name == column.column_prop
                      )[0].isSearch,
                      'el-icon-zoom-in': searchTableHead.filter(
                        (item) => item.name == column.column_prop
                      )[0].isSearch,
                    }"
                  />
                </span>
                <el-cascader
                  v-if="'depart_name' == column.column_prop"
                  v-model="searchParams.depart_id"
                  style="width: 80%"
                  clearable
                  size="small"
                  :options="departSymptom"
                  @change="reset"
                  :props="{ checkStrictly: true }"
                >
                </el-cascader>
                <el-select
                  v-if="'professionalName' == column.column_prop"
                  style="width: 80%"
                  @change="reset"
                  v-model="searchParams.professional"
                  clearable
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in doctorDic.doctor_title"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-if="'status_name' == column.column_prop"
                  v-model="searchParams.status"
                  clearable
                  placeholder="请选择"
                  size="small"
                  @change="getList"
                >
                  <template v-for="item in doctorStatus">
                    <el-option
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </template>
                </el-select>
                <el-select
                  v-if="'upload_name' == column.column_prop"
                  v-model="searchParams.upload_status"
                  clearable
                  placeholder="请选择"
                  size="small"
                  @change="getList"
                >
                  <template v-for="item in filingStatus">
                    <el-option
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </template>
                </el-select>
                <el-input
                  v-if="'doctor_name' == column.column_prop"
                  size="mini"
                  placeholder=""
                  prefix-icon="el-icon-search"
                  v-model="searchParams.doctor_name"
                  clearable
                  @change="
                    handleSearch(column.column_prop, searchParams.doctor_name)
                  "
                >
                </el-input>
                <el-input
                  v-if="'doctors_name' == column.column_prop"
                  size="mini"
                  placeholder=""
                  prefix-icon="el-icon-search"
                  v-model="searchParams.doctors_name"
                  clearable
                  @change="
                    handleSearch(column.column_prop, searchParams.doctors_name)
                  "
                >
                </el-input>
                <el-input
                  v-if="'mobile' == column.column_prop"
                  size="mini"
                  placeholder=""
                  prefix-icon="el-icon-search"
                  v-model="searchParams.mobile"
                  clearable
                  @change="
                    handleSearch(column.column_prop, searchParams.mobile)
                  "
                >
                </el-input>
              </el-popover>
              <span v-else>{{
                column.column_label_user_definition
                  ? column.column_label_user_definition
                  : column.column_label
              }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>

      <el-dialog title="发放补贴" :visible.sync="dialogVisible" width="50%">
        <el-form :model="form" :rules="incomeRules">
          <el-form-item label="补贴类型" label-width="200" prop="type">
            <el-select
              v-model="form.type"
              placeholder="请选择补贴类型"
              style="width:300px"
            >
              <el-option
                v-for="item in incomeOptions"
                v-bind:key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="问诊编号" label-width="200" prop="order_sn">
            <el-input
              v-model="form.order_sn"
              autocomplete="off"
              style="width:300px"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.type == 3"
            label="订单编号"
            label-width="200"
            prop="order_no"
          >
            <el-input
              v-model="form.order_no"
              autocomplete="off"
              style="width:300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="补贴金额" label-width="200" prop="amount">
            <el-input
              v-model="form.amount"
              autocomplete="off"
              style="width:300px"
              type="number"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleSendIncome()"
            >确 定</el-button
          >
        </div>
      </el-dialog>
      <el-drawer
        :append-to-body="true"
        size="76%"
        :visible.sync="dialogTableVisible"
        direction="rtl"
      >
        <physicianDetail
          @closeDrawer="closeDrawer"
          doctorType="1"
          :type="'view'"
          :info="info"
          :id="currUser.doctors_id"
          v-if="dialogTableVisible"
        >
        </physicianDetail>
      </el-drawer>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />
    </div>
    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
  </div>
</template>

<script>
import editTableHead from "@/components/editTableHead/editTableHead";
import { getAdminFieldIndex } from "@/api/drugs2.0";
import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
import physicianDetail from "../doctorManage/physicianDetail2";
import {
  getDoctorsDetailNew,
  physicianTermination,
  getDoctorsListNew,
} from "@/api/audit/doctor2.0.js";
import { putSendIncome } from "@/api/finance";
import { Message } from "element-ui";
import { mapState } from "vuex";

export default {
  name: "docBill",
  async created() {
    await this._getAdminFieldIndex();
    this.getList();
  },
  data() {
    return {
      table_type:'docBill',
      operateList: [],
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      // multipleSelection1: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "mobile",
          isSearch: false,
        },
        {
          name: "doctor_name",
          isSearch: false,
        },
        {
          name: "depart_name",
          isSearch: false,
        },
        {
          name: "professionalName",
          isSearch: false,
        },
        {
          name: "status_name",
          isSearch: false,
        },
        {
          name: "upload_name",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      form: {},
      doctorId: 0,
      dialogVisible: false,
      info: {},
      showPop: false,
      type: 1, //1--医师,2--药师
      selected: false,
      clearFilter: false,
      dialogTitle: "",
      dialogType: "",
      datetimerange: [],
      currUser: {},
      dialogTableVisible: false,
      searchParams: {
        orderTime: [],
      },
      incomeOptions: [
        { label: "图文问诊", value: 2 },
        { label: "视频问诊", value: 1 },
        { label: "其他补贴", value: 3 },
        //   {label:'其他',value:10},
      ],
      total: 0,
      listLoading: false,
      fullscreenLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      tableData: [],
      options: [],
      ruleForm: {
        examine: "",
      },
      rules: {
        examine: [
          {
            required: true,
            message: "请选择审核结果",
            trigger: "change",
          },
        ],
      },
      incomeRules: {
        type: [
          { required: true, message: "请选择补贴类型", trigger: "change" },
        ],
        order_sn: [{ required: true, message: "请填写问诊编号" }],
        order_no: [{ required: true, message: "请填写订单编号" }],
        amount: [{ required: true, message: "请填写金额" }],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  watch: {
    dialogTableVisible(n, v) {
      if (!n) {
        this.getList();
      }
    },
  },
  computed: {
    ...mapState({
      filingStatus: (state) => state.dictionary.filingStatus,
      doctorStatus: (state) => state.dictionary.doctorStatus,
      doctorDic: (state) => state.dictionary.doctorDic, //字典
      departSymptom: (state) => state.dictionary.departDic, //字典
      is_self: (state) => state.user.is_self,
      table_options: (state) => state.user.table_options,
    }),
  },
  components: {
    Pagination,
    physicianDetail,
    editTableHead,
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
    this.$store.dispatch("dictionary/getDicData", [
      {
        stateName: "doctorDic",
        api: "/admin/hospital/getDicts",
      },
    ]);
    this.$store.dispatch("dictionary/getDicData", [
      {
        stateName: "departDic",
        api: "/admin/hospital/getDeparts",
      },
    ]);
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val);
      let tmp = val.map((v, k, a) => {
        return v.appraise_id;
      });
      this.multipleSelection = `[${tmp.join(",")}]`;
      console.log(this.multipleSelection, 43435435);
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "docBill",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.docBill;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    handleSendIncome() {
      putSendIncome(this.doctorId, this.form)
        .then((res) => {
          if (res.code == 200) {
            this.dialogVisible = false;
            this.$message.success("补贴已发放！");
          } else {
            return this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendIncome(row) {
      console.log(11111, row);
      this.doctorId = row.doctors_id;
      this.dialogVisible = true;
    },
    //关闭弹窗,刷新列表
    closeDrawer(e) {},
    //暂未开放
    notOpen() {
      this.$message({
        message: "暂未开放",
        type: "warning",
      });
    },
    //清除高级搜索
    clearState() {
      this.searchParams.status = "";
      this.searchParams.upload_status = "";
    },
    approvalStatus(e) {
      this.searchParams.status = e;
    },
    clearText() {
      this.searchParams.doctor_name = "";
      this.searchParams.mobile = "";
      this.searchParams.depart_id = "";
      this.searchParams.professional = "";
      this.getList();
    },
    popoShow() {
      this.clearFilter = true;
      this.selected = true;
    },
    popoHide() {
      this.selected = false;
      this.clearFilter = false;
    },
    //解约医师
    physicianTermination(type, id) {
      this.$confirm(type == 1 ? "是否解约该医生?" : "是否删除该医生", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: id,
          };
          physicianTermination(params)
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: type == 1 ? "解约成功!" : "删除成功",
                });
                this.getList();
              }
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: type == 1 ? "已取消解约" : "已取消删除",
          });
        });
    },
    getInfo(id) {
      // this.fullscreenLoading = true;
      let params = {
        id: id,
      };
      getDoctorsDetailNew(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.info = {
              ..._data,
            };
            this.dialogTableVisible = true;
          }
          if (this.info.depart_id) {
            this.info.depart_id = Number(this.info.depart_id);
          }
          this.fullscreenLoading = false;
        })
        .catch((error) => {
          Message({
            message: error.message,
            type: "error",
          });
          this.fullscreenLoading = false;
        });
    },
    getList() {
      this.listLoading = true;
      this.fullscreenLoading = true;
      let params = {
        type: this.type,

        ...this.searchParams,
      };
      if (params.depart_id && params.depart_id.length == 2) {
        params.depart_id.splice(0, 1);
      }
      params.page = this.listQuery.page;
      params.page_size = this.listQuery.limit;
      getDoctorsListNew(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.list;
          }
          this.fullscreenLoading = false;
          this.listLoading = false;
        })
        .catch((error) => {
          Message({
            message: error.message,
            type: "error",
          });
          this.listLoading = false;
          this.fullscreenLoading = false;
        });
    },
    reset() {
      // this.searchParams = {};
      this.listQuery.page = 1;
      this.getList();
    },

    dateChange(val) {
      if (val && val.length) {
        this.searchParams.created_at = JSON.stringify(val);
      } else {
        this.searchParams.created_at = [];
      }
      this.getList();
    },
    editDoctor() {
      this.$router.push({
        path: "/credentManage/addDoctor",
        query: {
          type: "1",
          name: "doctor",
        },
      });
    },
    editSuccess() {
      alert("编辑成功");
      this.dialogTableVisible = false;
      this.getList();
    },
  },
  filters: {
    controllshow(str, num) {
      //如果当前字符串小于nunm，返回原值
      if (str) {
        if (str.length <= num) {
          return str;
        }
        if (str.length > num) {
          return str.substr(0, num - 1) + "...";
        }
      } else {
        return str;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.horizontal-collapse-transition {
  transition: 0s width ease-in-out, 0s padding-left ease-in-out,
    0s padding-right ease-in-out;
}

.header {
  display: flex;
  align-items: center;
  height: 26px;
  margin-bottom: 10px;

  i {
    width: 25px;
    height: 25px;
    background-color: #2632fb;
    color: white;
    line-height: 25px;
    text-align: center;
    border-radius: 4px;
    margin-right: 8px;
  }
}

.popover-width {
  background-color: red !important;
  font-size: 50px;
}

.title-filter {
  display: flex;
  height: 32px;
  line-height: 32px;

  .clearText {
    color: #3066fb;
    font-size: 13px;
  }

  .clearText:hover {
    cursor: pointer;
  }

  .filter:hover {
    cursor: pointer;
  }

  .advancedScreenings {
    background-color: white;
    width: 28px;
    height: 28px;
    line-height: 30px;
    border: 1px solid #c7c7c7;
    border-radius: 4px;
    margin-left: 10px;

    img {
      display: inline-block;
      margin-left: 5px;
      margin-top: 5px;
      width: 18px;
      height: 18px;
    }
  }

  .advancedScreening {
    background-color: rgb(24, 144, 255);
    width: 28px;
    height: 28px;
    line-height: 30px;
    border-radius: 4px;
    margin-left: 20px;

    img {
      display: inline-block;
      margin-left: 5px;
      margin-top: 5px;
      width: 18px;
      height: 18px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .new-doc {
    padding: 7px 8px;
    margin-left: 10px;
    box-sizing: border-box;
    height: 28px;
  }

  .btnn {
    height: 32px;
    padding: 0 10px;
    margin-left: 20px;
    line-height: 32px;
  }
}

.doc-title {
  position: relative;
  height: 30px;

  img {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 2px;
  }

  span {
    display: inline-block;
    margin-left: 30px;
    line-height: 30px;
    font-weight: bold;
  }
}

::v-deep .el-drawer {
  bottom: 0 !important;
}

::v-deep .el-drawer__header {
  padding: 0;
  margin: 0;
}

::v-deep .el-tabs__header {
  margin: 0;
}

::v-deep .el-drawer.rtl {
  overflow: auto;
  height: 91vh;
  bottom: 0;
  margin-top: 9vh;
  position: absolute;
}

::v-deep .el-drawer__container ::-webkit-scrollbar {
  display: none;
}

.btn {
  width: 53px;
  padding: 6px 0;
}

//::v-deep #el-popover-8416{
// 	background-color: red !important;
// }
// .el-popover{
// 	width: 100% !important;
// 	min-width: none !important;
// }
</style>
